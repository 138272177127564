<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <div style="height:70vh;overflow:auto;">
      <v-card-text v-if="!hasProfileItems">
        您还未完成任何测试
      </v-card-text>
      <v-timeline v-else class="px-4">
        <v-timeline-item
          v-for="pItem in personProfileItems"
          :key="pItem.itemGuid"
          small
        >
          <v-card v-if="pItem.itemType === profileType.result" elevation="7">
            <v-card-title>{{ "完成测试" }}</v-card-title>
            <v-card-text>
              <div class="text-body-1 mb-2">{{ `${pItem.lbDispName}` }}</div>
              <template v-for="conf in profileDispFieldConfig">
                <div :key="conf.guid" class="text--secondary">
                  {{ getProfileFieldContents(conf, pItem) }}
                </div>
              </template>
              <div>
                结果预警：
                <v-tooltip
                  bottom
                  v-for="(alert, idx) in pItem.caseAlertList"
                  :key="idx"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="!!alert.displayName"
                      :color="`${alert.colorHex}`"
                      v-on="on"
                      v-bind="attrs"
                    >
                      mdi-record
                    </v-icon>
                  </template>
                  <span>{{ alert.displayName }}</span>
                </v-tooltip>
                <span
                  v-if="!pItem.caseAlertList || !pItem.caseAlertList.length"
                >
                  无
                </span>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <AppTooltipBtn
                v-if="pItem.availableReportModes.length === 1"
                btn-class="mr-2 px-2"
                color="primary"
                icon="mdi-file-document-outline"
                label="查看报告"
                @click="
                  showReportDialog(
                    pItem.itemGuid,
                    pItem.itemNodeGuid,
                    pItem.availableReportModes[0]
                  )
                "
              ></AppTooltipBtn>
              <ReportModeMenuBtn
                v-if="pItem.availableReportModes.length > 1"
                v-model="selectedReportMode"
                :lb-id="pItem.lbId"
                :report-mode-list="pItem.availableReportModes"
                btn-class="mr-2 px-2"
                color="primary"
                icon="mdi-file-document-outline"
                label="查看报告"
                @click="
                  showReportDialog(pItem.itemGuid, pItem.itemNodeGuid, null)
                "
              />
            </v-card-actions>
          </v-card>
          <template v-slot:opposite>
            <span>{{ pItem.itemDate }}</span>
          </template>
        </v-timeline-item>
      </v-timeline>
    </div>
    <ReportDisplayerDialog
      v-model="isShowAdminReportDialog"
      displayer-type="admin"
      :case-guid-list="reportCaseGuidList"
      :node-guid-list="reportNodeGuidList"
      :report-mode="selectedReportMode"
      @closed="closeAdminReportDialog"
    />
    <AppMessageBox title="发生错误" v-model="errorMsg" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import ReportModeMenuBtn from "@/components/report/ReportModeMenuBtn";
import ReportDisplayerDialog from "@/components/report/ReportDisplayerDialog";
import { mapGetters } from "vuex";
import { fetchTestPersonProfileItems } from "@/api/person";
import { buildAgeString } from "@/utils/personUtils";
import {
  personProfileDispConfigFields,
  specialConfigField
} from "@/utils/constants/fieldConfig";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppTooltipBtn,
    ReportModeMenuBtn,
    ReportDisplayerDialog
  },

  props: {
    personGuid: {
      type: String,
      required: true
    },
    nodeGuid: {
      type: String,
      required: true
    },
    fieldConfigList: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      personProfileItems: [],
      errorMsg: "",
      profileType: {
        result: "result"
      },
      isShowAdminReportDialog: false,
      selectedReportMode: "",
      reportCaseGuidList: [],
      reportNodeGuidList: []
    };
  },

  watch: {
    personGuid(newVal) {
      if (newVal) {
        this.getPersonProfileItems(newVal);
      } else {
        this.personProfileItems = [];
        this.reportCaseGuidList = [];
      }
    }
  },

  computed: {
    ...mapGetters({
      regionGuid: "user/regionGuid"
    }),
    hasProfileItems() {
      return this.personProfileItems && this.personProfileItems.length;
    },
    profileDispFieldConfig() {
      return this.fieldConfigList.filter(conf =>
        personProfileDispConfigFields.includes(conf.fieldName)
      );
    }
  },

  methods: {
    async getPersonProfileItems(guid) {
      try {
        this.isLoading = true;
        this.personProfileItems = await fetchTestPersonProfileItems(
          guid,
          this.nodeGuid
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    getProfileFieldContents(fieldConfig, profileItem) {
      let fieldVal = profileItem.personInfo[fieldConfig.fieldName];
      if (fieldConfig.fieldName === specialConfigField.node.fieldName) {
        fieldVal =
          profileItem.personInfo[specialConfigField.node.dispFieldName];
      } else if (fieldConfig.fieldName === specialConfigField.age.fieldName) {
        fieldVal = this.buildAgeStringFromAgeObj(
          profileItem.personInfo[fieldConfig.fieldName]
        );
      }
      return `${fieldConfig.fieldAlias}: ${fieldVal}`;
    },
    buildAgeStringFromAgeObj(ageObj) {
      return buildAgeString({
        year: ageObj.year,
        month: ageObj.month,
        day: ageObj.day
      });
    },
    showReportDialog(itemGuid, itemNodeGuid, defaultReportMode) {
      this.reportCaseGuidList = [itemGuid];
      this.reportNodeGuidList = [itemNodeGuid];
      if (defaultReportMode) {
        this.selectedReportMode = defaultReportMode;
      }
      this.isShowAdminReportDialog = true;
    },
    closeAdminReportDialog() {
      this.isShowAdminReportDialog = false;
      this.$nextTick(() => {
        this.reportCaseGuidList = [];
        this.reportNodeGuidList = [];
        this.selectedReportMode = "";
      });
    }
  },

  created() {
    this.getPersonProfileItems(this.personGuid);
  }
};
</script>
