export const reportModeDict = {
  admin_brief: {
    value: "admin_brief",
    textDefault: "评估报告",
    textMmpi: "社会适应性-简要报告",
    textNuclear: "个人体检报告"
  },
  admin_detailed: {
    value: "admin_detailed",
    textDefault: "个人体检报告",
    textMmpi: "社会适应性-详细报告",
    textNuclear: "评估报告"
  },
  admin_hos_brief: {
    value: "admin_hos_brief",
    textDefault: "",
    textMmpi: "精神病性-简要报告",
    textNuclear: ""
  },
  admin_hos_detailed: {
    value: "admin_hos_detailed",
    textDefault: "",
    textMmpi: "精神病性-详细报告",
    textNuclear: ""
  }
};

const _mmpiLbIdRange = [19, 22, 78, 124, 174, 175];
const _nuclearLbIdRange = [114];

export function extractValueTextListFromReportModeList(reportModeList, lbId) {
  let modeDict = reportModeList.map(rptMode => reportModeDict[rptMode]);
  let modeTextList = [];
  if (_mmpiLbIdRange.includes(lbId)) {
    modeTextList = modeDict.map(md => ({
      value: md.value,
      text: md.textMmpi
    }));
  } else if (_nuclearLbIdRange.includes(lbId)) {
    modeTextList = modeDict.map(md => ({
      value: md.value,
      text: md.textNuclear
    }));
  } else {
    modeTextList = modeDict.map(md => ({
      value: md.value,
      text: md.textDefault
    }));
  }
  return modeTextList;
}

export const reportDownloadTypeDict = {
  zip: "zip",
  mergedPdf: "merged_pdf"
};

export const reportExportFormatType = {
  pdf: {
    name: "pdf",
    ext: ".pdf"
  },
  word: {
    name: "word",
    ext: ".docx"
  }
};
