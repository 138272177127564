<template>
  <AppMenuBtn
    :btn-class="btnClass"
    :color="color"
    :icon="icon"
    :tooltip="tooltip"
    :label="label"
  >
    <v-list>
      <v-list-item
        v-for="(mode, idx) in reportModeValueTextList"
        :key="idx"
        @click="btnClicked(mode.value)"
      >
        {{ mode.text }}
      </v-list-item>
    </v-list>
  </AppMenuBtn>
</template>

<script>
import AppMenuBtn from "@/components/AppMenuBtn";
import { extractValueTextListFromReportModeList } from "@/utils/constants/report";

export default {
  components: {
    AppMenuBtn
  },

  props: {
    reportMode: {
      type: String,
      required: true
    },
    lbId: {
      type: Number,
      required: true
    },
    reportModeList: {
      type: Array,
      required: true
    },
    btnClass: {
      type: String
    },
    color: {
      type: String
    },
    tooltip: {
      type: String
    },
    icon: {
      type: String
    },
    label: {
      type: String
    }
  },

  model: {
    prop: "reportMode",
    event: "change"
  },

  data() {
    return {
      localReportMode: this.reportMode
    };
  },

  watch: {
    reportMode(newVal) {
      this.localReportMode = newVal;
    },
    localReportMode(newVal) {
      this.$emit("change", newVal);
    }
  },

  computed: {
    reportModeValueTextList() {
      return extractValueTextListFromReportModeList(
        this.reportModeList,
        this.lbId
      );
    }
  },

  methods: {
    btnClicked(reportModeClicked) {
      if (reportModeClicked) {
        this.localReportMode = reportModeClicked;
        this.$nextTick(() => {
          this.$emit("click", reportModeClicked);
        });
      }
    }
  }
};
</script>
