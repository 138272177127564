var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppLoadingSpinner',{model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_c('div',{staticStyle:{"height":"70vh","overflow":"auto"}},[(!_vm.hasProfileItems)?_c('v-card-text',[_vm._v(" 您还未完成任何测试 ")]):_c('v-timeline',{staticClass:"px-4"},_vm._l((_vm.personProfileItems),function(pItem){return _c('v-timeline-item',{key:pItem.itemGuid,attrs:{"small":""},scopedSlots:_vm._u([{key:"opposite",fn:function(){return [_c('span',[_vm._v(_vm._s(pItem.itemDate))])]},proxy:true}],null,true)},[(pItem.itemType === _vm.profileType.result)?_c('v-card',{attrs:{"elevation":"7"}},[_c('v-card-title',[_vm._v(_vm._s("完成测试"))]),_c('v-card-text',[_c('div',{staticClass:"text-body-1 mb-2"},[_vm._v(_vm._s(("" + (pItem.lbDispName))))]),_vm._l((_vm.profileDispFieldConfig),function(conf){return [_c('div',{key:conf.guid,staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.getProfileFieldContents(conf, pItem))+" ")])]}),_c('div',[_vm._v(" 结果预警： "),_vm._l((pItem.caseAlertList),function(alert,idx){return _c('v-tooltip',{key:idx,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!!alert.displayName)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":("" + (alert.colorHex))}},'v-icon',attrs,false),on),[_vm._v(" mdi-record ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(alert.displayName))])])}),(!pItem.caseAlertList || !pItem.caseAlertList.length)?_c('span',[_vm._v(" 无 ")]):_vm._e()],2)],2),_c('v-card-actions',[_c('v-spacer'),(pItem.availableReportModes.length === 1)?_c('AppTooltipBtn',{attrs:{"btn-class":"mr-2 px-2","color":"primary","icon":"mdi-file-document-outline","label":"查看报告"},on:{"click":function($event){return _vm.showReportDialog(
                  pItem.itemGuid,
                  pItem.itemNodeGuid,
                  pItem.availableReportModes[0]
                )}}}):_vm._e(),(pItem.availableReportModes.length > 1)?_c('ReportModeMenuBtn',{attrs:{"lb-id":pItem.lbId,"report-mode-list":pItem.availableReportModes,"btn-class":"mr-2 px-2","color":"primary","icon":"mdi-file-document-outline","label":"查看报告"},on:{"click":function($event){return _vm.showReportDialog(pItem.itemGuid, pItem.itemNodeGuid, null)}},model:{value:(_vm.selectedReportMode),callback:function ($$v) {_vm.selectedReportMode=$$v},expression:"selectedReportMode"}}):_vm._e()],1)],1):_vm._e()],1)}),1)],1),_c('ReportDisplayerDialog',{attrs:{"displayer-type":"admin","case-guid-list":_vm.reportCaseGuidList,"node-guid-list":_vm.reportNodeGuidList,"report-mode":_vm.selectedReportMode},on:{"closed":_vm.closeAdminReportDialog},model:{value:(_vm.isShowAdminReportDialog),callback:function ($$v) {_vm.isShowAdminReportDialog=$$v},expression:"isShowAdminReportDialog"}}),_c('AppMessageBox',{attrs:{"title":"发生错误"},model:{value:(_vm.errorMsg),callback:function ($$v) {_vm.errorMsg=$$v},expression:"errorMsg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }