<template>
  <div>
    <AppDialog
      v-model="isShowDialog"
      persistent
      size="large"
      overflow-height="calc(100vh - 270px)"
      title="批量导入被试者信息"
      color="primary"
      action-icon="mdi-database-import"
      action-text="确认导入"
      cancel-text="关闭"
      @confirm="importUploadedPersonList"
    >
      <AppLoadingSpinner v-model="isLoading" />
      <template v-slot:title-right>
        <v-btn text color="primary" @click="openTemplateDownloadDialog">
          下载Excel模板
        </v-btn>
      </template>
      <div>第一步：选择需要导入的测评单位</div>
      <v-form ref="basicInfoForm" class="mx-4">
        <v-select
          label="导入的测评单位"
          :items="allNodeList"
          v-model="selectedNodeGuid"
          :rules="fieldRules.required"
        ></v-select>
      </v-form>
      <div class="mt-4">第二步：上传Excel并解析</div>
      <v-form ref="uploadForm" class="mx-4 d-flex align-center flex-wrap">
        <v-file-input
          v-model="uploadedFile"
          label="点击添加Excel"
          prepend-icon="mdi-file-excel"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :error="!!uploadErrorMsg"
          :error-messages="uploadErrorMsg"
          :rules="fieldRules.uploadedFile"
        ></v-file-input>
        <AppTooltipBtn
          btn-class="ml-8"
          color="primary"
          icon="mdi-file-upload-outline"
          label="上传"
          @click="parseExcelFile"
        />
      </v-form>
      <div class="mt-4">
        第三步：设置初始密码，并确认要上传的被试者信息，然后点击“批量导入”（建议一次导入不超过300人）
      </div>
      <v-text-field
        class="init-pwd-field mx-4"
        label="初始密码（可不填）"
        hide-details="auto"
        prepend-icon="mdi-lock"
        :type="isShowPassword ? 'text' : 'password'"
        :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
        v-model="initPassword"
        :rules="fieldRules.userInitPwd"
        @click:append="isShowPassword = !isShowPassword"
      ></v-text-field>
      <v-data-table
        no-data-text="点击“上传”获取此列表"
        :headers="uploadedPersonHeaders"
        :items="uploadedPersonList"
        disable-pagination
        hide-default-footer
      ></v-data-table>
    </AppDialog>
    <AppDialog
      v-model="isShowTemplateDialog"
      persistent
      size="small"
      overflow-height="calc(100vh - 270px)"
      title="Excel模板下载"
      color="primary"
      action-text="下载Excel模板"
      :loading="isDialogLoading"
      @confirm="downloadPersonFileTemplate"
    >
      <v-select
        label="模板标题的测评单位"
        :items="allNodeList"
        v-model="selectedNodeGuid"
        :rules="fieldRules.required"
      ></v-select>
      <v-select
        label="识别号类型"
        :items="identityTypes"
        v-model="selectedIdentityType"
        :rules="fieldRules.required"
      ></v-select>
      <v-slide-y-transition>
        <div v-if="!!selectedNodeGuid">
          <v-card-text>需要的字段（设置的必填字段自动加入）</v-card-text>
          <div class="ml-6">
            <template v-for="field in fieldConfigList">
              <v-switch
                :key="field.guid"
                v-if="
                  field.fieldAlias &&
                    (field.isRequired ||
                      field.isVisible ||
                      field.isVisibleInTable ||
                      field.isVisibleInReport)
                "
                inset
                :ripple="false"
                :value="field.fieldAlias"
                :label="field.fieldAlias"
                hide-details="auto"
                :disabled="field.isRequired"
                v-model="pickedFieldAliasList"
              ></v-switch>
            </template>
          </div>
        </div>
      </v-slide-y-transition>
    </AppDialog>
    <AppDialog
      v-model="isImporting"
      title="正在导入..."
      persistent
      cancel-text="关闭"
    >
      <div>请稍候。正在导入...</div>
      <v-progress-linear rounded indeterminate height="6"></v-progress-linear>
    </AppDialog>
    <AppMessageBox title="导入成功！" v-model="importSuccessMessage" />
    <AppMessageBox title="发生错误" v-model="errorMsg" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AppDialog from "@/components/AppDialog";
import {
  extractInfoDictFromFile,
  importPersonFromDictList,
  downloadPersonListTemplate
} from "@/api/person";
import { getUserFieldConfig } from "@/api/fieldConfig";
import { fetchManageNodeFlatSelectList } from "@/api/manageNode";
import { fetchVisibleIdentityTypeList } from "@/api/userIdentity";
import { specialConfigField } from "@/utils/constants/fieldConfig";
import { downloadFile } from "@/utils/download";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppTooltipBtn,
    AppDialog
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    regionGuid: {
      type: String
    },
    nodeGuids: {
      type: Array
    }
  },

  model: {
    prop: "value",
    event: "change"
  },

  data() {
    return {
      isLoading: false,
      isDialogLoading: false,
      isImporting: false,
      errorMsg: "",
      uploadErrorMsg: "",
      importSuccessMessage: "",
      // prepared data
      allNodeList: [],
      identityTypes: [],
      fieldConfigList: [],
      isShowPassword: false,
      initPassword: "",
      // upload
      selectedNodeGuid: "",
      selectedNodeName: "",
      selectedIdentityType: "",
      uploadedFile: null,
      uploadedPersonList: [],
      uploadedPersonHeaders: [],
      fieldRules: {
        uploadedFile: [val => !!val || "请点击上传文件"],
        required: [val => (val || "").length > 0 || "必填"],
        userInitPwd: [
          val =>
            (val || "").length < 1 ||
            (val || "").length >= 6 ||
            "新密码不能少于6位"
        ]
      },
      // dialog
      isShowDialog: this.value,
      isShowTemplateDialog: false,
      pickedFieldAliasList: []
    };
  },

  watch: {
    value(newVal) {
      this.isShowDialog = newVal;
    },
    isShowDialog(newVal) {
      this.$emit("change", newVal);
      if (newVal) {
        this.prepareNodeAndIdentityTypes();
      } else {
        this.resetSelectedData();
        this.$emit("closed");
      }
    },
    selectedNodeGuid(newVal) {
      if (newVal) {
        let node = this.allNodeList.find(n => n.value === newVal);
        if (node) {
          this.getFieldConfig();
          this.selectedNodeName = node.text;
          return;
        }
      }
      this.selectedNodeName = "";
    }
  },

  methods: {
    resetSelectedData() {
      this.selectedNodeGuid = "";
      this.selectedIdentityType = "";
      this.pickedFieldAliasList = [];
      this.uploadedPersonHeaders = [];
      this.uploadedFile = null;
      this.isShowPassword = false;
      this.initPassword = "";
      this.$refs.basicInfoForm.resetValidation();
      this.$refs.uploadForm.resetValidation();
    },
    async getFieldConfig() {
      try {
        this.isLoading = true;
        this.fieldConfigList = await getUserFieldConfig([
          this.selectedNodeGuid
        ]);
        // 去除 node 作为 Excel 的字段，因为导入前需要在页面中选择需要导入的 node
        // 同时去除 age 字段，因为 age 是自动计算出来的
        this.fieldConfigList = this.fieldConfigList.filter(
          fc =>
            fc.fieldName !== specialConfigField.node.fieldName &&
            fc.fieldName !== specialConfigField.age.fieldName
        );
        // 加入必填字段，必填字段必须要加入到 Excel 中
        this.pickedFieldAliasList = this.fieldConfigList
          .filter(fc => fc.fieldAlias && fc.isRequired)
          .map(fc => fc.fieldAlias);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async prepareNodeAndIdentityTypes() {
      try {
        this.isLoading = true;
        this.allNodeList = await fetchManageNodeFlatSelectList(
          this.regionGuid,
          this.nodeGuids
        );
        let visibleIdTypes = await fetchVisibleIdentityTypeList(
          this.regionGuid,
          null,
          this.nodeGuids
        );
        if (!visibleIdTypes || !visibleIdTypes.length) {
          this.errorMsg = "尚未添加任何识别号类型，请联系管理员进行添加。";
        }
        this.identityTypes = visibleIdTypes;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    // ============================ upload & import person ============================
    async parseExcelFile() {
      let isBasicInfoValid = this.$refs.basicInfoForm.validate();
      let isFileValid = this.$refs.uploadForm.validate();
      if (isBasicInfoValid && isFileValid) {
        try {
          this.isLoading = true;
          this.uploadedPersonList = await extractInfoDictFromFile(
            this.uploadedFile
          );
          if (this.uploadedPersonList && this.uploadedPersonList.length) {
            let headerList = Object.keys(this.uploadedPersonList[0]);
            this.uploadedPersonHeaders = headerList.map(hText => ({
              text: hText,
              value: hText
            }));
          }
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isLoading = false;
      }
    },
    async importUploadedPersonList() {
      if (this.uploadedPersonList && this.uploadedPersonList.length) {
        try {
          this.isImporting = true;
          let importOutput = await importPersonFromDictList(
            this.selectedNodeGuid,
            this.uploadedPersonList,
            this.initPassword
          );
          let duplicateCountMsg =
            importOutput.duplicateCount > 0
              ? `发现 ${importOutput.duplicateCount} 个已导入的被试者`
              : "";
          this.importSuccessMessage = `成功导入 ${importOutput.importedCount} 个被试者 ${duplicateCountMsg}`;
          // 导入成功后关闭窗口
          this.isShowDialog = false;
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isImporting = false;
      }
    },
    // ============================ download import person template ============================
    openTemplateDownloadDialog() {
      this.isShowTemplateDialog = true;
    },
    async downloadPersonFileTemplate() {
      try {
        this.isDialogLoading = true;
        let downloadPath = await downloadPersonListTemplate({
          regionGuid: this.regionGuid,
          nodeGuid: this.selectedNodeGuid,
          nodeName: this.selectedNodeName,
          identityType: this.selectedIdentityType,
          fieldNameList: this.pickedFieldAliasList
        });
        downloadFile(downloadPath, "被试者批量导入模板.xlsx");
      } catch (err) {
        this.errorMessage = err.message;
      }
      this.isDialogLoading = false;
    }
  },

  created() {
    this.prepareNodeAndIdentityTypes();
  }
};
</script>

<style lang="scss" scoped>
.init-pwd-field {
  max-width: 400px;
}
</style>
